import {createSelector} from 'reselect';
import {RootState, Tag, Intent} from './types';

export const contextSelector = (state: RootState) => state.context.context;
export const errorsSelector = (state: RootState) => state.context.errors;

export const tagCountSelector = createSelector(
  contextSelector,
  (tags) => tags.length,
);

// return values of tags that have intent that eqauls one in intents
export const valuesOfTagsWithIntent = (intents: (Intent | undefined)[]) =>
  createSelector(contextSelector, (tags) =>
    tags
      .filter((item) => intents.includes(item.intent))
      .map((item) => item.value),
  );

export const languageSelector = createSelector(
  contextSelector,
  (tags) =>
    tags.find(
      (tag: Tag) => tag.axis === 'environment' && tag.label === 'language',
    )?.value as string,
);

export const lastErrorSelector = createSelector(
  errorsSelector,
  (errors) => errors[errors.length - 1],
);
