import {extendTheme} from '@chakra-ui/react';
import {createBreakpoints} from '@chakra-ui/theme-tools';
import {styles} from '../styles';
import {colors} from '../colors';
import {components} from '../components';
import {base} from '../components/Base';

export const fonts = {
  heading: `Arial, 'Verdana', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `Arial, 'Verdana', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

export const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
}); // ["480px", "768px", "992px", "1280px"]

export const sizes = {
  container: {
    xl: '1120px',
  },
};

export const fontWeights = {
  thin: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const fontSizes = {
  '4xl': '2rem',
};

export const lineHeights = {
  base: '1.75rem',
  tall: '2rem',
};

export const shadows = {
  default: '0px 24px 34px -13px rgba(0, 39, 118, 0.13)',
};

export const radii = {
  none: '0',
  sm: '0.25rem',
  base: '0.5rem',
  md: '0.75rem',
  lg: '1rem',
  xl: '1.25rem',
  '2xl': '2.5rem',
  '3xl': '3.75rem',
  full: '9999px',
};

export const layerStyles = {
  tile: {
    shadow: shadows.default,
    transition: 'box-shadow 0.3s ease',
    borderRadius: 'xl',
    bg: 'white',
    _hover: {
      shadow: '0px 24px 34px -13px rgba(0, 39, 118, 0.2)',
    },
  },
};

export const textStyles = {
  link: {
    fontWeight: 'normal',
    _hover: {
      textDecoration: 'underline',
    },
  },
  h1: base.headings.h1,
  intro: {
    fontSize: '1.438rem',
    lineHeight: ['2.25rem', null, null, '2.438rem'],
  },
};

export const theme = extendTheme({
  fonts,
  fontWeights,
  breakpoints,
  styles,
  colors,
  shadows,
  components,
  fontSizes,
  layerStyles,
  textStyles,
  radii,
  sizes,
  lineHeights,
});

export type DefaultTheme = typeof theme;
