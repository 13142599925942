import {colors} from '../colors';
import {hexToRGB} from '../../utils/utils';

const baseStyleOverlay = {
  bg: hexToRGB(colors.primary, '0.1'),
  zIndex: 'modal',
  boxShadow: 'default',
};

function baseStyleDialog() {
  return {
    boxShadow: 'default',
    mx: [6, 0],
    my: [24, 14],
  };
}

function baseStyleBody() {
  return {
    px: [6, 12],
    py: 10,
  };
}

const baseStyleCloseButton = {
  position: 'absolute',
  top: [-16, 0],
  right: [0, -14],
  insetEnd: 3,
  color: 'link',
  borderRadius: 'full',
  bg: 'white',
  w: 10,
  h: 10,
  _focus: {
    boxShadow: 'none',
  },
};

const baseStyleHeader = {
  px: 0,
  py: 0,
};

const baseStyle = () => ({
  overlay: baseStyleOverlay,
  dialog: baseStyleDialog(),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(),
});

const defaultProps = {
  size: '3xl',
};

export const Modal = {
  baseStyle,
  defaultProps,
};
