import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {languageSelector} from '../../lib/context/selectors';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}
export const LanguageProvider = ({children}: Props) => {
  const language = useSelector(languageSelector);
  const {i18n} = useTranslation();
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      document.documentElement.lang = language;
    }
  }, [i18n, language]);

  return <>{children}</>;
};
