import {ChakraProvider} from '@chakra-ui/react';
import {AppProps} from 'next/app';
import React, {useEffect, useRef, useState} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {Hydrate} from 'react-query/hydration';
import {GlobalStyles} from '../theme/components/GlobalStyles';
import {useTheme} from '../hooks/useTheme';
import '../providers/i18n/i18n';
import {AnimatePresence} from 'framer-motion';
import Head from 'next/head';
import {ContextProvider} from '../lib/context/ContextProvider';
import {LanguageProvider} from '../providers/LanguageProvider';
import {makeServer} from './../mockApi';
import {ContextVisualizerProvider} from '../providers/ContextVisualizerProvider';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {reactPlugin, appInsights} from '../monitoring/appInsights';
import {AppInsightsErrorBoundary} from '@microsoft/applicationinsights-react-js';
import {ErrorBoundaryContent} from '../components/ErrorBoundaryContent/ErrorBoundaryContent';
import getConfig from 'next/config';
import {trackingInitializerGTM} from '../utils/trackingGTM';

const {publicRuntimeConfig} = getConfig();

if (
  process.env.NODE_ENV === 'development' &&
  publicRuntimeConfig.NEXT_PUBLIC_ENABLE_MOCK === 'true'
) {
  makeServer();
}

// react query SSR hydration https://react-query.tanstack.com/guides/ssr

function MyApp({Component, pageProps, router}: AppProps) {
  const queryClientRef = useRef(new QueryClient());
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //prevent double initialisation
    if (!isLoading) {
      if (
        process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING &&
        publicRuntimeConfig.NEXT_PUBLIC_ENVIRONMENT === 'production'
      ) {
        appInsights.loadAppInsights();
      }

      //trackingInitializer();
      trackingInitializerGTM();
      setIsLoading(true);
    }
  }, [isLoading]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ChakraProvider resetCSS theme={theme}>
        <GlobalStyles />
        <AppInsightsErrorBoundary
          appInsights={reactPlugin}
          onError={() => <ErrorBoundaryContent />}>
          <QueryClientProvider client={queryClientRef.current}>
            <Hydrate state={pageProps.dehydratedState}>
              <ContextProvider>
                <LanguageProvider>
                  <AnimatePresence exitBeforeEnter>
                    <Head key="123">
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
                      />
                    </Head>

                    <ContextVisualizerProvider key="234">
                      <Component {...pageProps} key={router.route} />
                    </ContextVisualizerProvider>
                  </AnimatePresence>
                </LanguageProvider>
              </ContextProvider>
            </Hydrate>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AppInsightsErrorBoundary>
      </ChakraProvider>
    </AppInsightsContext.Provider>
  );
}

export default MyApp;
