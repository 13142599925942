import getConfig from 'next/config';
import TagManager from 'react-gtm-module';
const {publicRuntimeConfig} = getConfig();

const isTrackingEnabled = () => {
  return publicRuntimeConfig.NEXT_PUBLIC_ENABLE_TRACKING === 'true';
};

export const trackingInitializerGTM = () => {
  if (isTrackingEnabled()) {
    TagManager.initialize({
      gtmId: publicRuntimeConfig.NEXT_PUBLIC_GTM as string,
    });
  }
};
