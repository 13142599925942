import {Box, Text, Heading, Button} from '@chakra-ui/react';
import {Main} from '../_shared/Layout/Main';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';

export const ErrorBoundaryContent = () => {
  const {t} = useTranslation();
  const router = useRouter();

  const onButtonClick = () => {
    router.push('/');
  };

  return (
    <Main>
      <Box mt={20} mb={140} as="section">
        <Heading variant="h1">{t('500.title')}</Heading>
        <Text>{t('500.subtext')}</Text>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Button
            onClick={onButtonClick}
            size="lg"
            mr={3}
            fontWeight="medium"
            color="white">
            {t('500.clientsideerror.gotohome')}
          </Button>
        </Box>
      </Box>
    </Main>
  );
};
