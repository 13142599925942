export const styles = {
  global: {
    body: {
      bg: 'url("/assets/images/bg.png") top left',
      bgSize: 'cover',
      fontWeight: 'light',
      color: 'bodyText',
      fontSize: 18,
      lineHeight: ['base', null, null, 'tall'],
    },
    p: {
      mb: 10,
    },
  },
};
