import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      // don't use runtimeConfig here, otherwise the 404 and 500 page will not work since they are statically generated
      process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,

    extensions: [reactPlugin],
  },
});
export {reactPlugin, appInsights};
