import {Server} from 'miragejs';
import getConfig from 'next/config';
import header from './mock/header.json';
import introduction from './mock/introduction.json';
import serviceoverview from './mock/serviceoverview.json';
import list from './mock/list.json';
import contact from './mock/contact.json';
import faq from './mock/faq.json';
import blogoverview from './mock/blogoverview.json';
import blog from './mock/blog.json';
import slugBlog from './mock/slugblog.json';
import slugService from './mock/slugservice.json';
import service from './mock/service.json';
import footer from './mock/footer.json';

const {publicRuntimeConfig} = getConfig();

export function makeServer() {
  let server = new Server({
    routes() {
      this.urlPrefix = 'https://dev.api.klmhealthservices.com';
      this.namespace = '/v1/content/item';

      // do no mock local API calls
      this.passthrough(`${publicRuntimeConfig.NEXT_PUBLIC_CDN_URL}*`);

      // https://github.com/vercel/next.js/issues/16874#issuecomment-723488275
      this.passthrough((request) => {
        if (
          request.url === '/_next/static/development/_devPagesManifest.json' ||
          request.url ===
            '/_next/static/development/_devMiddlewareManifest.json'
        ) {
          return true;
        }
      });

      this.get(
        `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}`,
        (schema, request) => {
          const queryParams = request.queryParams;
          const language = queryParams.culture || 'nl';

          switch (queryParams.type) {
            case 'header':
              return header[language];
            case 'introduction':
              return introduction[language];
            case 'serviceoverview':
              return serviceoverview[language];
            case 'list':
              return list[language];
            case 'contact':
              return contact[language];
            case 'faqset':
              return faq[language];
            case 'blogoverview':
              return blogoverview[language];
            case 'blog':
              if (queryParams.slug) {
                return slugBlog[language];
              }
              return blog[language];
            case 'service':
              if (queryParams.slug) {
                return slugService[language];
              }
              return service[language];
            case 'footer':
              return footer[language];
            default:
              return {};
          }
        },
      );
    },
  });
  return server;
}
