import {
  Action,
  ActionWithPayload,
  Context,
  ContextState,
  Tag,
  ActionWithTag,
  Intent,
} from '../types';

const initialState: ContextState = {
  context: [],
  errors: [],
};

const findSingleTagByLabel = (context: Context, label: string) => {
  return context.find((element) => element.label === label && element.single);
};

const findIntentTagByValue = (context: Context, tag: Tag) => {
  return context.find(
    (element) =>
      element.axis === tag.axis &&
      element.label === 'intent' &&
      element.value === tag.value,
  );
};

const findTagByLabelAndValue = (
  context: Context,
  label: string,
  value: string | number,
) => {
  return context.find(
    (element) => element.label === label && element.value === value,
  );
};

const withError = (state: ContextState, error: string) => {
  return {
    ...state,
    errors: [...state.errors, error],
  };
};

export const isPrevious = (intentA: Intent, intentB: Intent) => {
  const intentOrder: Intent[] = ['awareness', 'interest', 'decision', 'action'];
  const indexA = intentOrder.findIndex((item) => item === intentA);
  const indexB = intentOrder.findIndex((item) => item === intentB);
  return indexA < indexB;
};

export const contextReducer = (
  state: ContextState = initialState,
  action: Action,
) => {
  const {context, errors} = state;

  const {type} = action;

  switch (type) {
    case 'ADD_TAG': {
      const {payload} = action as ActionWithPayload;
      const foundTag = findSingleTagByLabel(context, payload.label);

      if (foundTag) {
        return withError(
          state,
          `tag marked single with label '${payload.label}' already exist in context`,
        );
      }

      return {
        ...state,
        context: [...context, payload as Tag],
      };
    }

    case 'ADD_IF_NOT_EXISTS': {
      const {payload} = action as ActionWithPayload;
      const foundTag = findTagByLabelAndValue(
        context,
        payload.label,
        payload.value,
      );

      if (!foundTag) {
        return {
          ...state,
          context: [...context, payload as Tag],
        };
      }

      return {
        ...state,
      };
    }

    case 'CHANGE_TAG': {
      const {payload} = action as ActionWithPayload;
      const foundTag = findSingleTagByLabel(context, payload.label);

      if (!foundTag) {
        return withError(
          state,
          `single tag '${payload.axis}:${payload.label}' not found`,
        );
      }

      if (!foundTag.mutable) {
        return {
          ...state,
          errors: [
            ...errors,
            `can't change immutable tag '${payload.axis}:${payload.label}'`,
          ],
        };
      }

      const newContext = context.map((item: Tag) => {
        if (
          item.axis === payload.axis &&
          item.label === payload.label &&
          item.single
        ) {
          return {...item, value: payload.value};
        } else {
          return item;
        }
      });

      return {
        ...state,
        context: newContext,
      };
    }

    case 'REMOVE_TAG': {
      const {payload} = action as ActionWithPayload;
      const foundTag = context.find(
        (element) =>
          element.label === payload.label &&
          element.axis === payload.axis &&
          element.value === payload.value,
      );

      if (!foundTag) {
        return withError(
          state,
          `can't remove non-existing tag '${payload.axis}:${payload.label}=${payload.value}'`,
        );
      }

      if (!foundTag.removable) {
        return withError(
          state,
          `can't remove non-removable tag '${payload.axis}:${payload.label}=${payload.value}'`,
        );
      }

      const newContext = context.filter((item: Tag) => {
        if (
          item.axis === payload.axis &&
          item.label === payload.label &&
          item.value === payload.value &&
          item.removable
        ) {
          return undefined;
        } else {
          return item;
        }
      });
      return {
        ...state,
        context: newContext,
      };
    }

    case 'RESET': {
      return initialState;
    }

    case 'ADD_OR_UPDATE_INTENT': {
      const {payload} = action as ActionWithTag;

      if (payload.label !== 'intent' || !payload.intent) {
        return withError(
          state,
          'cannot add or update intent of a non-intent tag',
        );
      }

      const foundTag = findIntentTagByValue(context, payload);

      if (!foundTag) {
        return {
          ...state,
          context: [...context, payload as Tag],
        };
      }

      if (!foundTag.intent) {
        if (payload.label !== 'intent' || !payload.intent) {
          return withError(
            state,
            'context contains invalid tag: label=intent with empty intent',
          );
        }
      }

      if (isPrevious(payload.intent, foundTag.intent!)) {
        return withError(
          state,
          'cannot update intent to previous stage of AIDA',
        );
      }

      if (foundTag.intent) {
        if (
          payload.intent === foundTag.intent &&
          payload.score < foundTag.score
        ) {
          return withError(
            state,
            'cannot decrease score for same intent of AIDA',
          );
        }
      }

      const newContext = context.map((item: Tag) => {
        if (
          item.axis === payload.axis &&
          item.label === payload.label &&
          item.value === payload.value
        ) {
          return {...item, intent: payload.intent, score: payload.score};
        } else {
          return item;
        }
      });

      return {
        ...state,
        context: newContext,
      };
    }

    default:
      return state;
  }
};
