import {useRouter} from 'next/router';
import {WORLDS} from '../../../constants/enums';
import {KlmGlobalStyles} from './KlmGlobalStyles';
import {DefaultGlobalStyles} from './DefaultGlobalStyles';

export const GlobalStyles = () => {
  const router = useRouter();

  switch (router?.route) {
    case WORLDS.KLM:
      return <KlmGlobalStyles />;
    default:
      return <DefaultGlobalStyles />;
  }
};
