import React from 'react';
import {Container, HTMLChakraProps} from '@chakra-ui/react';
import {MotionBox} from '../../MotionBox/MotionBox';

interface MainProps extends HTMLChakraProps<'div'> {
  children: React.ReactNode | React.ReactNode[];
}

export const Main = ({children, ...props}: MainProps) => (
  <Container maxW="container.xl" px={[8, null, null, null, 4]} {...props}>
    <MotionBox
      initial={{opacity: 0, scale: 0.9}}
      animate={{opacity: 1, scale: 1}}
      exit={{opacity: 0, scale: 0.9}}>
      {children}
    </MotionBox>
  </Container>
);
