export const base = {
  headings: {
    h1: {
      fontSize: '2.938rem',
      lineHeight: ['4.375rem', null, null, '4.625rem'],
      mb: [12, null, null, 14],
      fontWeight: 'light',
    },
    h2: {
      fontSize: '2.313rem',
      lineHeight: ['3.5rem', null, null, '3.688rem'],
      mb: [7, null, null, 8],
    },
    h3: {
      fontSize: '1.813rem',
      lineHeight: ['2.75rem', null, null, '3rem'],
      mb: 5,
    },
    h4: {
      fontSize: '1.438rem',
      lineHeight: ['2.25rem', null, null, '2.438rem'],
      mb: 3,
    },
  },
};
