import React, {ReactNode} from 'react';
import {Provider} from 'react-redux';
import {createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {rootReducer} from './reducers/rootReducer';
import {Action, RootState} from './types';

type DispatchType = (args: Action) => Action;

const store: Store<RootState, Action> & {
  dispatch: DispatchType;
} = createStore(rootReducer, composeWithDevTools());

export const ContextProvider = ({children}: {children: ReactNode}) => {
  return <Provider store={store}>{children}</Provider>;
};
