import React from 'react';
import {Box, Tag as ChakraTag, Text} from '@chakra-ui/react';
import {Axis, Tag} from '../../lib/context/types';
import {useSelector} from 'react-redux';

import {
  contextSelector,
  lastErrorSelector,
  tagCountSelector,
} from '../../lib/context/selectors';

export const ContextVisualizer = () => {
  const allTags = useSelector(contextSelector);
  const countTags = useSelector(tagCountSelector);
  const lastError = useSelector(lastErrorSelector);

  const tagsByAxis = (tags: Tag[], axis: Axis): Tag[] => {
    return tags.filter((item) => item.axis === axis);
  };

  return (
    <>
      <Box background="white" p={4} borderRadius={4}>
        <Text>Aantal tags: {countTags}</Text>
        <Text>State:</Text>
        {tagsByAxis(allTags, 'state').map((item, index) => {
          return (
            <ChakraTag key={index} background="tomato" color="white" m={1}>
              label={item.label} intent={item.intent} value={item.value} score=
              {item.score}
            </ChakraTag>
          );
        })}
        <Text>Event:</Text>
        {tagsByAxis(allTags, 'event').map((item, index) => {
          return (
            <ChakraTag key={index} background="tomato" color="white" m={1}>
              label={item.label} intent={item.intent} value={item.value} score=
              {item.score}
            </ChakraTag>
          );
        })}
        <Text>Environment:</Text>
        {tagsByAxis(allTags, 'environment').map((item, index) => {
          return (
            <ChakraTag key={index} background="tomato" color="white" m={1}>
              label={item.label} intent={item.intent} value={item.value} score=
              {item.score}
            </ChakraTag>
          );
        })}
      </Box>

      <Box background="red.300" p={4}>
        <Text color="white">Last error: {lastError}</Text>
      </Box>
    </>
  );
};
