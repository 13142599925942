const green = '#14726C';
const blue = '#296FAB';
const darkBlue = '#1C255C';
const grey = '#d9d9d9';
export const overrideStylesPhilipsEhm = {
  styles: {
    global: {
      body: {
        bg: `linear-gradient(to right, #27ADCE ,${blue});`,
        fontWeight: 'light',
        color: 'bodyText',
        fontSize: 18,
        lineHeight: ['base', null, null, 'tall'],
      },
      p: {
        mb: 10,
      },
    },
  },
  colors: {
    bodyText: darkBlue,
    headerBackground: 'white',
    visualListBackground: darkBlue,
    servicesOverviewHeadingColor: 'white',
    faqHeadingColor: 'white',
    faqBackgroundHover: grey,
    contactBackground: darkBlue,
    blogOverviewHeadingColor: 'white',
    footerColor: 'white',
    footerLinkColor: 'white',
    faqIconColor: green,
    footerLinkArrowColor: 'white',
    selectedColor: blue,
    introBackground: darkBlue,
    footerUspArrowColor: 'white',
    footerBackground: '',
    blogDetailBackgroundColor: 'white',
    primaryLinkColor: green,
    secondaryBackground: green,
    secondaryBackgroundIconColor: 'white',
    closeIconColor: green,
    closeIconBackgroundHover: grey,
  },
};
