import {colors} from '../colors';

type Dict = Record<string, any>;

function variantSubtle(props: Dict) {
  const {colorScheme: c} = props;
  let bg;
  let caption;
  switch (c) {
    case 'info':
      bg = colors.alertInfo;
      caption = colors.alertInfoCaption;
      break;
  }

  return {
    container: {bg: bg, color: caption},
  };
}

const variants = {
  subtle: variantSubtle,
};

const defaultProps = {
  variant: 'subtle',
  colorScheme: 'blue',
};

export const Alert = {
  variants,
  defaultProps,
};
