import {useRouter} from 'next/router';
import {WORLDS} from '../constants/enums';
import {theme as defaultTheme} from '../theme/themes/default';
import {extendTheme} from '@chakra-ui/react';
import {merge} from '../utils/deepMerge';
import {overrideStylesKlm} from '../theme/themes/klm';
import {overrideStylesHeineken} from '../theme/themes/heineken';
import {overrideStylesPhilipsEhm} from '../theme/themes/philipsEhm';

export const useTheme = () => {
  const router = useRouter();

  if (!router) {
    return defaultTheme;
  }

  // return theme based on host
  // if no result try a route match
  if (typeof window !== 'undefined') {
    const host = window.location.host;

    if (host.includes(WORLDS.EHM_PHILIPS)) {
      return extendTheme(merge(defaultTheme, overrideStylesPhilipsEhm));
    }
  }

  const matchRoute = (route: string) => {
    if (router.route.includes(route)) {
      return router.route;
    }
  };

  switch (router.route) {
    case matchRoute(WORLDS.KLM):
      return extendTheme(merge(defaultTheme, overrideStylesKlm));
    case matchRoute(WORLDS.HEINEKEN):
      return extendTheme(merge(defaultTheme, overrideStylesHeineken));
  }
  return defaultTheme;
};
