import React, {useState} from 'react';
import {Box, Button} from '@chakra-ui/react';
import {ContextVisualizer} from '../../components/ContextDemo/ContextVisualizer';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}
export const ContextVisualizerProvider = ({children}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((previousVal) => !previousVal);
  };

  if (process?.env?.NODE_ENV !== 'development') {
    return <>{children}</>;
  }

  return (
    <>
      <Box position="fixed" left={20} bottom={2}>
        {isOpen && (
          <Box>
            <ContextVisualizer />
            <Button onClick={toggleOpen}>X Hide Context</Button>
          </Box>
        )}
        {!isOpen && <Button onClick={toggleOpen}>Show Context</Button>}
      </Box>
      {children}
    </>
  );
};
