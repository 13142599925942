type Dict = Record<string, any>;

function variantSolid(props: Dict) {
  const {colorScheme: c} = props;
  if (c === 'primary') {
    return {
      bg: 'primaryButtonBackground',
      _active: {
        backgroundColor: 'primaryButtonBackground',
      },
      _hover: {
        opacity: 0.8,
      },
      _focus: {
        boxShadow: 'none',
      },
    };
  }
}

const variants = {
  solid: variantSolid,
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'primary',
};

export const Button = {
  variants,
  defaultProps,
};
